import React from "react";
import { Link } from "gatsby";

import { LOGIN_ROUTE } from "@routes";

const ResetPasswordSuccess = () => (
  <div>
    <p>Se ha enviado un correo electrónico con su nueva contraseña.</p>
    <Link to={LOGIN_ROUTE}>Iniciar sesión</Link>
  </div>
);

export default ResetPasswordSuccess;
