import React from "react";
import PropTypes from "prop-types";

import { CenterAligned, ButtonCss } from "./ResetPasswordContent.style";

import Input from "components/Input";
import Button from "components/Button";

const labels = {
  email: "Dirección de correo eletrónico",
};

const ResetPasswordForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
}) => (
  <form onSubmit={handleSubmit}>
    <Input
      label={labels.email}
      name="email"
      value={values.email}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.email && touched.email && errors.email}
    />
    <CenterAligned>
      <Button css={ButtonCss} type="submit">
        Cambiar
      </Button>
    </CenterAligned>
  </form>
);

ResetPasswordForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
