import React from "react";
import { Formik } from "formik";

import { resetPasswordUser } from "apiClient/users";

import isValidEmail from "utils/isValidEmail";

import UserLayout from "layouts/UserLayout";

import ResetPasswordContent from "pagesComponents/ResetPassword/ResetPasswordContent";
import ResetPasswordSuccess from "pagesComponents/ResetPassword/ResetPasswordSuccess";

const getInitialValues = (filledValues) => ({
  email: "",
  ...filledValues,
});

class ResetPasswordPage extends React.PureComponent {
  state = {
    errors: {},
    sent: false,
  };

  validate = (values) => {
    let errors = {};

    if (!values.email) {
      errors.email = "Requerido";
    } else if (!isValidEmail(values.email)) {
      errors.email = "Dirección de correo no valida.";
    }

    return errors;
  };

  onChangeName = (name) => {
    this.setState((state) => {
      const currentErrors = state.errors;
      delete currentErrors[name];

      return { errors: { ...currentErrors } };
    });
  };

  onSubmit = async (values) => {
    try {
      await resetPasswordUser(values.email);

      this.setState({
        sent: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { sent } = this.state;

    if (sent) {
      return (
        <UserLayout title="Reestablecer contraseña">
          <ResetPasswordSuccess />
        </UserLayout>
      );
    }

    return (
      <UserLayout title="Reestablecer contraseña">
        <Formik
          initialValues={getInitialValues()}
          validate={this.validate}
          onSubmit={this.onSubmit}
        >
          {({ errors, handleChange, ...formikProps }) => (
            <ResetPasswordContent
              {...formikProps}
              errors={{ ...errors, ...this.state.errors }}
              handleChange={(event) => {
                this.onChangeName(event.target.name);
                handleChange(event);
              }}
            />
          )}
        </Formik>
      </UserLayout>
    );
  }
}

export default ResetPasswordPage;
